import { App } from 'vue'
import SvgIcon from '@/components/SvgIcon/SvgIcon.vue'
import NotifyComponent from '@/components/NotifyComponent/NotifyComponent.vue'
import FormElement from '@/components/FormElement/FormElement.vue'
import FormActionBtns from '@/components/ui/FormActionBtns/FormActionBtns.vue'
import FileLoader from '@/components/FileLoader/FileLoader.vue'
import EditBtn from '@/components/ui/EditBtn/EditBtn.vue'
import AppLoader from '@/components/ui/AppLoader/AppLoader.vue'
import ImageGallery from '@/components/ImageGallery/ImageGallery.vue'
import AppImageLoad from '@/components/AppImageLoad/AppImageLoad.vue'
import SelectElement from '@/components/ui/SelectElement/SelectElement.vue'

const globalComponents = (app: App) => {
  app.component('SvgIcon', SvgIcon)
  app.component('NotifyComponent', NotifyComponent)
  app.component('FormElement', FormElement)
  app.component('FormActionBtns', FormActionBtns)
  app.component('FileLoader', FileLoader)
  app.component('EditBtn', EditBtn)
  app.component('AppLoader', AppLoader)
  app.component('ImageGallery', ImageGallery)
  app.component('AppImageLoad', AppImageLoad)
  app.component('SelectElement', SelectElement)
}

export default globalComponents
