<template>
  <div class="gallery">
    <div v-if="$slots.title || expandable" class="gallery__header">
      <slot name="title" class="gallery__header-title" />
      <EditBtn
        v-if="expandable"
        class="gallery__header-button gallery__header-button_location_top"
        @click="toggleImagesView"
        >{{ expandText }}</EditBtn
      >
    </div>
    <TransitionGroup
      v-if="galleryImages && galleryImages.length"
      name="list"
      tag="ul"
      class="gallery__list"
    >
      <li
        v-for="{ id, url } of galleryImages"
        :key="id"
        class="gallery__list-item"
      >
        <img :src="url" alt="Изображение" />
      </li>
    </TransitionGroup>
    <EditBtn
      v-if="expandable"
      class="gallery__header-button gallery__header-button_location_bottom"
      @click="toggleImagesView"
      >{{ expandText }}</EditBtn
    >
  </div>
</template>

<script lang="ts" src="./ImageGallery.ts"></script>

<style scoped lang="scss" src="./ImageGallery.scss"></style>
