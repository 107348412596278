import { defineComponent, onMounted, onUnmounted } from 'vue'
import MenuComponent from '@/components/MenuComponent/MenuComponent.vue'
import { Breakpoints } from '@/enum/Breakpoints'
import { useUIStore } from '@/store/ui/ui'
import { setSessionStorageItem } from '@/helpers/sessionStorage'

export default defineComponent({
  name: 'MainLayout',
  components: {
    MenuComponent,
  },
  setup() {
    const UIStore = useUIStore()

    const toggleMenu = () => {
      UIStore.toggleMenu()
      setSessionStorageItem('isMenuOpen', UIStore.getIsMenuOpen)
    }

    const checkMenuVisibility = () => {
      const breakpoint = Breakpoints.NOTEBOOK

      if (window.innerWidth < breakpoint && UIStore.getIsMenuOpen) {
        toggleMenu()
      }
    }

    onMounted(() => {
      window.addEventListener('resize', checkMenuVisibility)
    })

    onUnmounted(() => {
      // Remove event listener to prevent memory leaks
      window.removeEventListener('resize', checkMenuVisibility)
    })

    return { UIStore, toggleMenu }
  },
})
