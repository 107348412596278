<template>
  <div class="select">
    <span v-if="title" class="select__title">{{ title }}</span>
    <Multiselect
      id="selected"
      v-model="selected"
      :options="options"
      :label="label"
      :searchable="searchable"
      :clear-on-select="false"
      :allow-empty="allowEmpty"
      :placeholder="placeholder"
      :close-on-select="closeOnSelect"
      :show-labels="false"
      :multiple="multiple"
      :option-height="104"
      :disabled="disabled"
    >
      <!--:track-by="objectKey"-->
      <template #noOptions>
        <span>Список пуст</span>
      </template>
      <template #placeholder v-if="isRequired">
        <span class="select__placeholder">{{ placeholder }}<span>*</span></span>
      </template>

      <template #noResult>
        <span>Не найдено</span>
      </template>

      <template #option="props">
        <div class="select__option">
          {{ props.option }}
          <SvgIcon v-if="multiple" icon="plus" />
        </div>
      </template>

      <template #caret="{ toggle }">
        <SvgIcon
          icon="chevron-down"
          class="caret"
          @mousedown.prevent.stop="toggle"
        />
      </template>
    </Multiselect>
    <div v-if="errorText" class="select__error">
      <span v-for="(error, index) in errorText" :key="index">{{ error }}</span>
    </div>
  </div>
</template>

<script lang="ts" src="./SelectElement.ts"></script>

<style lang="scss" src="./SelectElement.scss"></style>
