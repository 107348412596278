<template>
  <div class="code">
    <div class="flex-col-8">
      <h2>Введите код подтверждения</h2>
      <p class="code__number">
        Код отправлен на номер
        {{ hiddenNumber ? `+${hiddenNumber}` : '' }}
        <!-- <span v-if="phone" class="code__link" @click="$emit('changeClick')"
          >Изменить</span
        > -->
      </p>
    </div>
    <div class="flex-col-8">
      <FormElement
        v-model:value="value"
        tagName="input"
        type="number"
        placeholder="Введите код подтверждения"
        v-maska="'#####'"
        autocomplete="disabled"
      />
      <div class="code__timer-wrapper">
        <span v-if="timer > 0" class="code__timer"
          >Получить новый код можно через 0:{{
            timer >= 10 ? timer : `0${timer}`
          }}</span
        >
        <span class="code__link" v-else @click="$emit('sendAgainClick')"
          >Выслать повторно</span
        >
      </div>
    </div>
  </div>
</template>

<script lang="ts" src="./CodeComponent.ts"></script>

<style scoped lang="scss" src="./CodeComponent.scss"></style>
