import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'FileLoader',
  props: {
    accept: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    text: {
      type: String,
    },
    secondaryText: {
      type: String,
    },
  },
  emits: ['fileLoad'],
  setup() {
    const input = ref<HTMLInputElement | null>(null)
    return { input }
  },
})
