import { defineComponent, computed, ref, PropType } from 'vue'
import { APP_MAX_IMAGES_TO_DISPLAY } from '@/constants/constants'
import type { IImage } from '@/types/app'

export default defineComponent({
  name: 'ImageGallery',
  props: {
    items: {
      type: Array as PropType<IImage[]>,
      required: true,
    },
    expandable: {
      type: Boolean,
    },
  },
  setup(props) {
    const galleryImages = computed(() =>
      props.items
        ? props.items.slice(
            0,
            props.expandable ? galleryImagesSlice.value : undefined,
          )
        : [],
    )
    const galleryImagesSlice = ref<number | undefined>(
      APP_MAX_IMAGES_TO_DISPLAY,
    )

    const expandText = computed(() =>
      props.expandable && galleryImages.value.length === props.items.length
        ? 'Скрыть'
        : 'Показать все',
    )

    const toggleImagesView = () => {
      if (galleryImages.value.length === props.items.length) {
        galleryImagesSlice.value = APP_MAX_IMAGES_TO_DISPLAY
      } else {
        galleryImagesSlice.value = undefined
      }
    }
    return { galleryImages, galleryImagesSlice, expandText, toggleImagesView }
  },
})
