import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'NotifyComponent',
  props: {
    text: {
      type: String,
      required: true,
    },
    status: {
      type: String as PropType<'done' | 'error' | 'info'>,
      required: true,
    },
    size: {
      type: String as PropType<'sm' | 'lg'>,
      required: false,
      default: 'sm',
    },
    closable: {
      type: Boolean,
      required: false,
    },
  },
  setup() {
    return {}
  },
})
