<template>
  <main>
    <div class="logo">Logo</div>
    <slot />
  </main>
</template>

<script lang="ts" src="./AuthLayout.ts"></script>

<style scoped lang="scss" src="./AuthLayout.scss"></style>
