<template>
  <aside class="menu">
    <section class="border-bottom menu__header">Logo</section>
    <nav>
      <ul class="border-bottom menu__list">
        <li v-for="{ icon, title, path } of menuItems.top" :key="path" class="">
          <RouterLink :to="path" class="menu__list-item">
            <SvgIcon :icon="icon" />
            <p>{{ title }}</p>
          </RouterLink>
        </li>
      </ul>
      <ul class="menu__list">
        <li v-for="{ icon, title, path } of menuItems.bottom" :key="path">
          <RouterLink :to="path" class="menu__list-item">
            <SvgIcon :icon="icon" />
            <p>{{ title }}</p>
          </RouterLink>
        </li>
      </ul>
    </nav>
  </aside>
</template>

<script lang="ts" src="./MenuComponent.ts"></script>

<style scoped lang="scss" src="./MenuComponent.scss"></style>
