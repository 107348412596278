import { defineComponent } from 'vue'
import RegistryForm from '@/components/RegistryForm/RegistryForm.vue'

export default defineComponent({
  name: 'RegistryView',
  components: {
    RegistryForm,
  },
  setup() {
    return {}
  },
  // Add props, data, methods, etc. as needed
})
