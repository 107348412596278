import { defineComponent, ref, watch, computed } from 'vue'
import { formatPhoneNumber } from '@/helpers/formatPhoneNumber'

export default defineComponent({
  name: 'CodeComponent',
  props: {
    timer: {
      type: Number,
      required: true,
    },
    phone: {
      type: String,
    },
  },
  emits: ['update:code', 'changeClick', 'sendAgainClick'],
  setup(props, { emit }) {
    const value = ref('')

    const hiddenNumber = computed(() => {
      if (!props.phone) {
        return ''
      }
      const hiddenPhone = `${props.phone.replace(/^(\d)(\d{6})/, '$1******')}`
      return formatPhoneNumber(hiddenPhone)
    })

    watch(
      () => value.value,
      (newValue) => {
        emit('update:code', newValue)
      },
    )

    return { value, hiddenNumber }
  },
})
