<template>
  <div class="content">
    <Transition name="slide">
      <MenuComponent v-show="UIStore.getIsMenuOpen" />
    </Transition>
    <header :class="['header', { collapsed: UIStore.getIsMenuOpen }]">
      <button type="button" @click="toggleMenu">
        <SvgIcon icon="menu" />
      </button>
      <div class="header__container">
        Admin
        <button type="button">
          <SvgIcon icon="chevron-down" />
        </button>
      </div>
    </header>
    <main :class="['main', { collapsed: UIStore.getIsMenuOpen }]">
      <slot />
    </main>
  </div>
</template>

<script lang="ts" src="./MainLayout.ts"></script>

<style scoped lang="scss" src="./MainLayout.scss"></style>
