import { defineComponent, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { RouterView } from 'vue-router'
import { useAuthStore } from '@/store/auth/auth'
import MainLayout from '@/layouts/MainLayout/MainLayout.vue'
import AuthLayout from '@/layouts/AuthLayout/AuthLayout.vue'

export default defineComponent({
  name: 'App',
  components: {
    RouterView,
    AuthLayout,
    MainLayout,
  },
  setup() {
    const route = useRoute()
    const router = useRouter()

    const authStore = useAuthStore()

    const layoutComponent = computed(() => `${route.meta.layout}Layout`)
    const componentKey = computed(() => {
      return route.path
    })

    const ALLOWED_PAGES_WITHOUT_AUTH = [
      'login',
      'recovery',
      'update',
      'registry',
    ]

    router.beforeEach((to, from, next) => {
      if (
        !authStore.isAuth &&
        !ALLOWED_PAGES_WITHOUT_AUTH.includes(String(to.name))
      ) {
        next({ name: 'login' })
        return
      }
      next()
    })

    return { layoutComponent, componentKey }
  },
})
