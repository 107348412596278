import { defineComponent, reactive, computed, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useAuthStore } from '@/store/auth/auth'
import { RouterLink } from 'vue-router'
import CodeComponent from '@/components/CodeComponent/CodeComponent.vue'

export default defineComponent({
  name: 'AuthForm',
  components: {
    RouterLink,
    CodeComponent,
  },
  setup() {
    const fields = reactive({
      email: '',
      password: '',
    })
    const isPasswordShown = ref<boolean>(false)
    const isCodeStep = ref(false)
    const code = ref('')
    const timer = ref(0)
    const isLoginLoading = ref(false)
    const reqError = ref('')
    const phone = ref<string>('')

    const authStore = useAuthStore()
    const router = useRouter()

    const FIELDS_PLACEHOLDERS = {
      email: 'E-mail',
      password: 'Пароль',
    }

    const fieldsTypes = computed(() => ({
      email: 'email',
      password: isPasswordShown.value ? 'text' : 'password',
    }))

    watch(timer, (newTime) => {
      if (newTime > 0) {
        setTimeout(() => timer.value--, 1000)
      }
    })

    const validationErrors = ref<{
      email?: string[]
      password?: string[]
    } | null>(null)

    const isEmailTyped = computed(() => fields.email.trim().length)
    const isEmailAnEmail = computed(() => fields.email.includes('@'))
    const isPasswordValid = computed(() => fields.password.trim().length)

    const validateField = (field: string) => {
      if (validationErrors.value && field in validationErrors.value) {
        delete validationErrors.value[
          field as keyof typeof validationErrors.value
        ]
      }
      if (field === 'email') {
        if (!isEmailAnEmail.value) {
          validationErrors.value = {
            ...validationErrors.value,
            email: ['Проверьте адрес электронной почты'],
          }
        }
        if (!isEmailTyped.value) {
          validationErrors.value = {
            ...validationErrors.value,
            email: ['Поле "E-mail" является обязательным'],
          }
        }
      }
      if (field === 'password' && !isPasswordValid.value) {
        validationErrors.value = {
          ...validationErrors.value,
          password: ['Поле "Пароль" является обязательным'],
        }
      }
    }

    const togglePassword = () => {
      isPasswordShown.value = !isPasswordShown.value
    }

    const resetErrors = () => {
      validationErrors.value = null
      reqError.value = ''
    }

    const handleLoginSubmit = async () => {
      isLoginLoading.value = true
      resetErrors()
      Object.keys(fields).forEach((field) => validateField(field))
      if (validationErrors.value !== null) {
        return
      }
      isPasswordShown.value = false

      await authStore.adminLogin(fields)

      if (authStore.error) {
        if (authStore.error.error) {
          reqError.value = authStore.error.error
        }

        if (authStore.error.data) {
          validationErrors.value = authStore.error.data
        }
        isLoginLoading.value = false

        return
      }

      if (authStore.loginResponse) {
        const phoneFromMessage = authStore.loginResponse.match(/\d{11}/)
        phone.value =
          phoneFromMessage && phoneFromMessage.length > 0
            ? phoneFromMessage[0]
            : ''
      }

      isLoginLoading.value = false
      isCodeStep.value = true
      timer.value = 59
    }

    const handleSendSMS = async () => {
      isLoginLoading.value = true
      resetErrors()

      const body = {
        phone: phone.value,
      }

      await authStore.adminSendSMS(body)

      if (authStore.error) {
        if (authStore.error.error) {
          reqError.value = authStore.error.error
        }
        isLoginLoading.value = false
        return
      }

      timer.value = 59
      isLoginLoading.value = false
    }

    const handleCompleteSubmit = async () => {
      isLoginLoading.value = true
      reqError.value = ''
      validationErrors.value = null

      const body = {
        phone: phone.value,
        code: code.value,
      }

      await authStore.adminCompleteLogin(body)

      if (authStore.isAuth) {
        await router.push({ name: 'home' })
      }

      if (authStore.error) {
        if (authStore.error.error) {
          reqError.value = authStore.error.error
        }
        isLoginLoading.value = false

        return
      }

      isLoginLoading.value = false
    }

    const submitHandler = computed(() =>
      isCodeStep.value ? handleCompleteSubmit : handleLoginSubmit,
    )

    const isCompleteSubmitBtnDisabled = computed(() =>
      isCodeStep.value ? code.value.length !== 5 : false,
    )

    return {
      fields,
      isCodeStep,
      code,
      FIELDS_PLACEHOLDERS,
      fieldsTypes,
      timer,
      phone,
      reqError,
      validationErrors,
      isPasswordShown,
      togglePassword,
      submitHandler,
      isCompleteSubmitBtnDisabled,
      handleSendSMS,
    }
  },
})
