import { defineComponent, ref, PropType } from 'vue'

export default defineComponent({
  name: 'FormElement',
  props: {
    title: {
      type: String,
      required: false,
    },
    tagName: {
      type: String as PropType<'input' | 'textarea'>,
      required: true,
    },
    disabled: { type: Boolean, required: false, default: false },
    errorText: { type: Array, required: false },
    value: { type: String, required: true },
    placeholder: { type: String, required: false },
    maxLength: { type: Number, required: false },
    isPlaceholderStatic: { type: Boolean, required: false },
  },
  emits: ['update:value'],
  setup(_, { emit }) {
    const focused = ref(false)

    const onInput = (event: Event & { target: HTMLInputElement }) => {
      const { value } = event.target as HTMLInputElement
      if (event.target.inputMode === 'numeric') {
        const numValue = value.replace(/[^\d]/g, '')
        event.target.value = numValue
        emit('update:value', numValue)
      } else {
        emit('update:value', value)
      }
    }

    const onFocus = () => {
      focused.value = true
    }

    const onBlur = () => {
      focused.value = false
    }

    return { focused, onInput, onFocus, onBlur }
  },
})
