import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/HomeView/HomeView.vue'
import AuthView from '@/views/AuthView/AuthView.vue'
import RegistryView from '@/views/RegistryView/RegistryView.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash }
    if (savedPosition) return savedPosition
    return { x: 0, top: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView,
      meta: {
        layout: 'Main',
      },
    },
    {
      path: '/builder',
      name: 'builder',
      component: () => import('@/views/BuilderView/BuilderView.vue'),
      meta: {
        layout: 'Main',
      },
    },
    {
      path: '/login',
      children: [
        {
          path: '',
          name: 'login',
          component: AuthView,
          meta: {
            layout: 'Auth',
          },
        },
        {
          path: 'recovery',
          name: 'recovery',
          component: AuthView,
          meta: {
            layout: 'Auth',
          },
        },
      ],
    },
    {
      path: '/registry',
      name: 'registry',
      component: RegistryView,
      meta: {
        layout: 'Auth',
      },
    },
  ],
})

export default router
