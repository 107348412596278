import { defineComponent } from 'vue'

export default defineComponent({
  name: 'EditBtn',
  emits: ['click'],
  setup() {
    return {}
  },
  // Add props, data, methods, etc. as needed
})
