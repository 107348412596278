<template>
  <section>
    <AuthForm v-if="componentShown.login" />
    <PasswordRecovery v-if="componentShown.recovery" />
  </section>
</template>

<script lang="ts" src="./AuthView.ts"></script>

<style scoped lang="scss" src="./AuthView.scss"></style>
