export const APP_MAX_IMAGES_TO_DISPLAY = 4

export const APP_MIN_IMAGES_TO_LOAD = 2
export const APP_MAX_IMAGES_TO_LOAD = 10

export const iOS_IMAGES_RESOLUTION = {
  'iphone55-8': '1242 px - 2208 px',
  'iphone65-11': '1242 px - 2688 px',
  'iphone67-14': '1242 px - 2688 px',
  ipadPro3: '2048 px - 2732 px',
}

export const IOS_DEVICES_KEYS = {
  'iphone55-8': 'iphone5_5-8',
  'iphone65-11': 'iphone6_5-11',
  'iphone67-14': 'iphone6_7-14',
  ipadPro3: 'ipad_pro_3',
}

export const ANDROID_IMAGES_RESOLUTION = {
  marketBanner: 'PNG, JPG 1024px x 500px',
  marketImage: '320px x 3840px',
}

export const APP_LANGUAGES = [
  'Русский',
  'Английский',
  'Китайский',
  'Испанский',
  'Немецкий',
  'Французский',
  'Португальский',
]

export const APP_CATEGORIES = [
  'Автомобили и транспорт',
  'Библиотеки и демоверсии',
  'Бизнес',
  'Видеопроигрыватели и редакторы',
  'Всё для дома',
  'Еда и напитки',
  'Здоровье и фитнес',
  'Знакомства',
  'Инструменты',
  'Искусство и дизайн',
  'Карты и навигация',
  'Книги и справочники',
  'Комиксы',
  'Красота',
  'Материнство и детство',
  'Медицина',
  'Музыка и аудио',
  'Новости и журналы',
  'Образование',
  'Персонализация',
  'Погода',
  'Покупки',
  'Путешествия',
  'Работа',
  'Развлечения',
  'Связь',
  'События',
  'Социальные',
  'Спорт',
  'Стиль жизни',
  'Финансы',
  'Фотография',
]
