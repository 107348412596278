import { defineComponent, reactive, computed, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import CodeComponent from '@/components/CodeComponent/CodeComponent.vue'
import { useAuthStore } from '@/store/auth/auth'
import { useApiCall } from '@/composables/useApiCall'
import { registryAdminApiCall } from '@/api/auth'
import {
  EMAIL_REG_EXP,
  PASSWORD_REG_EXP,
  PHONE_EXCESSIVE_CHARS_REG_EXP,
  PHONE_REG_EXP,
} from '@/constants/regExp'
import { VALIDATION_MESSAGES } from '@/constants/validationMessages'
import type { DefaultError } from '@/types/httpError'
import type { RegistryFormPayload } from '@/types/auth'

export default defineComponent({
  name: 'RegistryForm',
  components: {
    CodeComponent,
  },
  setup() {
    const fields = reactive({
      name: '',
      email: '',
      password: '',
      repeatPassword: '',
      phone: '',
    })
    const code = ref<string>('')
    const timer = ref<number>(0)
    const arePasswordsShown = reactive({
      password: false,
      repeatPassword: false,
    })
    const isCodeStep = ref(false)
    const isPhoneChangeClicked = ref(false)
    const errorFields = ref<Partial<DefaultError> | null>(null)
    const reqError = ref<string>('')
    const isCodeLoading = ref(false)

    const router = useRouter()
    const authStore = useAuthStore()

    watch(timer, (newTime) => {
      if (newTime > 0) {
        setTimeout(() => timer.value--, 1000)
      }
    })

    const FIELDS_PLACEHOLDERS = {
      name: 'ФИО',
      email: 'Контактный E-mail',
      password: 'Пароль',
      repeatPassword: 'Повторите пароль',
      phone: 'Телефон',
    }

    const fieldsTypes = computed(() => ({
      name: 'text',
      email: 'email',
      password: arePasswordsShown.password ? 'text' : 'password',
      repeatPassword: arePasswordsShown.repeatPassword ? 'text' : 'password',
      phone: 'tel',
    }))

    const {
      isLoading: registryLoading,
      data: registryAdminData,
      executeApiCall: registryAdminAction,
      error: registryAdminError,
    } = useApiCall<'', DefaultError, RegistryFormPayload>(
      registryAdminApiCall,
      true,
    )

    const isLoading = computed(
      () => registryLoading.value || isCodeLoading.value,
    )

    const showPassword = (key: keyof typeof arePasswordsShown) => {
      arePasswordsShown[key] = !arePasswordsShown[key]
    }

    // валидация
    const isPasswordValid = computed(() =>
      PASSWORD_REG_EXP.test(fields.password),
    )
    const isRepeatPasswordValid = computed(
      () => fields.password === fields.repeatPassword,
    )
    const isEmailValid = computed(() =>
      fields.email ? EMAIL_REG_EXP.test(fields.email) : true,
    )
    const isPhoneValid = computed(() =>
      fields.phone ? PHONE_REG_EXP.test(fields.phone) : true,
    )

    watch(
      [isPasswordValid, isRepeatPasswordValid],
      ([isPassValidValue, isRepeatPassValidValue]) => {
        delete errorFields.value?.data?.password
        delete errorFields.value?.data?.repeatPassword
        if (!isPassValidValue) {
          errorFields.value = {
            data: {
              ...errorFields.value?.data,
              password: [VALIDATION_MESSAGES.password.validationErr],
            },
          }
        }
        if (!isRepeatPassValidValue) {
          errorFields.value = {
            data: {
              ...errorFields.value?.data,
              repeatPassword: [
                VALIDATION_MESSAGES.repeatPassword.notSameAsPasswordErr,
              ],
            },
          }
        }
      },
    )

    watch(
      () => isEmailValid.value,
      (newVal) => {
        delete errorFields.value?.data?.email
        if (!newVal) {
          errorFields.value = {
            data: {
              ...errorFields.value?.data,
              email: [VALIDATION_MESSAGES.email.validationErr],
            },
          }
        }
      },
      { immediate: true },
    )

    watch(
      () => isPhoneValid.value,
      (newVal) => {
        delete errorFields.value?.data?.phone

        if (!newVal) {
          errorFields.value = {
            data: {
              ...errorFields.value?.data,
              phone: [VALIDATION_MESSAGES.phone.validationErr],
            },
          }
        }
      },
    )

    const isSubmitBtnDisabled = computed(() =>
      !isCodeStep.value
        ? !Object.values(fields).every((field) => field.trim().length) ||
          fields.phone.length !== 16 ||
          !isPasswordValid.value ||
          !isRepeatPasswordValid.value
        : code.value.length < 5,
    )

    const onChangePhoneClick = () => {
      isCodeStep.value = false
      isPhoneChangeClicked.value = true
    }

    const resetErrors = () => {
      errorFields.value = null
      reqError.value = ''
    }

    const handleRegistryFormSubmit = async () => {
      resetErrors()

      const body = {
        ...fields,
        phone: fields.phone.replace(PHONE_EXCESSIVE_CHARS_REG_EXP, ''),
      }

      try {
        await registryAdminAction(body)

        if (registryAdminData.value) {
          arePasswordsShown.password = false
          arePasswordsShown.repeatPassword = false
          isCodeStep.value = true
          timer.value = 59
        }
      } catch {
        if (registryAdminError.value) {
          errorFields.value = registryAdminError.value.data.data
        }
      }
    }

    const handleSendSMS = async () => {
      resetErrors()
      isCodeLoading.value = true

      const body = {
        phone: fields.phone.replace(PHONE_EXCESSIVE_CHARS_REG_EXP, '').slice(1),
      }

      await authStore.adminSendSMS(body)

      if (authStore.error) {
        if (authStore.error.error) {
          reqError.value = authStore.error.error
        }
        isCodeLoading.value = false
        return
      }

      timer.value = 59
      isCodeLoading.value = false
    }

    const handleCompleteSubmit = async () => {
      resetErrors()
      isCodeLoading.value = true

      const body = {
        phone: fields.phone.replace(PHONE_EXCESSIVE_CHARS_REG_EXP, '').slice(1),
        code: code.value,
      }

      await authStore.adminCompleteLogin(body)

      if (authStore.error) {
        if (authStore.error.error) {
          reqError.value = authStore.error.error
        }
        isCodeLoading.value = false

        return
      }

      if (authStore.isAuth) {
        await router.push({ name: 'home' })
      }
      isCodeLoading.value = false
    }

    const submitHandler = computed(() =>
      isCodeStep.value ? handleCompleteSubmit : handleRegistryFormSubmit,
    )
    return {
      fields,
      code,
      timer,
      errorFields,
      reqError,
      arePasswordsShown,
      isCodeStep,
      isPhoneChangeClicked,
      FIELDS_PLACEHOLDERS,
      PHONE_EXCESSIVE_CHARS_REG_EXP,
      fieldsTypes,
      showPassword,
      isLoading,
      isEmailValid,
      isPhoneValid,
      VALIDATION_MESSAGES,
      isSubmitBtnDisabled,
      submitHandler,
      handleSendSMS,
      onChangePhoneClick,
    }
  },
})
