import { defineComponent } from 'vue'

export default defineComponent({
  name: 'MenuComponent',
  setup() {
    const menuItems = {
      top: [
        {
          icon: 'home',
          title: 'Главная',
          path: '/',
        },
      ],
      bottom: [
        {
          icon: 'puzzle',
          title: 'Конструктор',
          path: '/builder',
        },
      ],
    }

    return { menuItems }
  },
})
