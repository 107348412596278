import { defineComponent, PropType } from 'vue'
import { iOSImageType } from '@/types/app'

export default defineComponent({
  name: 'AppImageLoad',
  props: {
    heading: {
      type: String,
    },
    images: {
      type: Array as PropType<iOSImageType[]>,
      required: true,
    },
    groupKey: {
      type: String,
      required: true,
    },
    fileLoaderSecondaryText: {
      type: String,
    },
  },
  emits: ['remove', 'load'],
  setup() {
    return {}
  },
})
