<template>
  <div class="image-load">
    <p v-if="heading" class="image-load__title">{{ heading }}</p>
    <slot name="info" />
    <TransitionGroup tag="ul" name="list" class="image-load__list">
      <li v-for="{ id, preview } of images" :key="id">
        <div
          v-if="preview"
          :style="{ backgroundImage: `url(${preview})` }"
          class="image-load__list-preview"
        >
          <button type="button" @click="$emit('remove', groupKey, id)"></button>
        </div>
        <FileLoader
          v-else
          accept="image/*"
          :id="groupKey"
          text="Загрузите изображение"
          :secondaryText="fileLoaderSecondaryText"
          class="image-load__loader"
          @fileLoad="(evt: Event) => $emit('load', evt, id)"
        />
      </li>
    </TransitionGroup>
  </div>
</template>

<script lang="ts" src="./AppImageLoad.ts"></script>

<style scoped lang="scss" src="./AppImageLoad.scss"></style>
